import axios from 'axios';
import {
  LiveStream,
  LiveStreamKey,
  LiveStreamStatus,
  StreamRemainingTime,
  StreamType,
} from 'common/interfaces/api';

export const createStream = async ({
  streamingKeyId,
  publishTime,
  thumbnail,
  videoSource,
}: {
  streamingKeyId: number;
  publishTime: string;
  videoSource: StreamType;
  thumbnail: string;
}) => {
  return (
    await axios.post(`/streamings/`, {
      streaming_key_id: streamingKeyId,
      planned_to_start_at: publishTime,
      video_source: String(videoSource),
      thumbnail: thumbnail,
    })
  ).data as { data: LiveStream };
};

export const updateStream = async ({
  streamingKeyId,
  publishTime,
  thumbnail,
  videoSource,
  streamId,
}: {
  streamingKeyId: number;
  publishTime: string;
  videoSource: StreamType;
  thumbnail: string;
  streamId: number;
}) => {
  return (
    await axios.put(`/streamings/${streamId}`, {
      streaming_key_id: streamingKeyId,
      planned_to_start_at: publishTime,
      video_source: String(videoSource),
      thumbnail: thumbnail,
    })
  ).data as { data: LiveStream };
};

export const getUpComingStreams = async (page: number) => {
  return (
    await axios.get(
      `/streamings/?statuses[]=before_live&statuses[]=in_live&page=${page}`
    )
  ).data as {
    data: { streamings: LiveStream[] };
    total: number;
  };
};

export const getEndedStreams = async (page: number) => {
  return (await axios.get(`/streamings/?statuses[]=ended&page=${page}`))
    .data as {
    data: { streamings: LiveStream[] };
    total: number;
  };
};

export const getStreamInfo = async (id: number) => {
  return (await axios.get(`/streamings/${id}`)).data as { data: LiveStream };
};

export const getStreamInfoPromise = async (id: number) => {
  return await axios.get(`/streamings/${id}`);
};

export const getStreamToken = async (streamId: number) => {
  return (await axios.get(`/streamings/${streamId}/token`)).data as {
    data: { token: string };
  };
};

export const createStreamKey = async ({ keyName }: { keyName: string }) => {
  return (await axios.post(`/streamings/keys/`, { name: keyName })).data as {
    data: LiveStreamKey;
  };
};

export const updateStreamKey = async ({
  keyId,
  name,
  isDefault = false,
}: {
  keyId: number;
  name: string;
  isDefault?: boolean;
}) => {
  return await axios.put(`/streamings/keys/${keyId}`, {
    name: name,
    default: isDefault,
  });
};

export const deleteStreamKey = async ({ keyId }: { keyId: number }) => {
  return await axios.delete(`/streamings/keys/${keyId}`);
};

export const getAllStreamKeys = async () => {
  return (await axios.get(`/streamings/keys/`)).data as {
    data: { streaming_keys: LiveStreamKey[] };
  };
};

export const startStream = async (id: number) => {
  return await axios.post(`/streamings/${id}/start`);
};

export const stopStream = async (id: number) => {
  return await axios.post(`/streamings/${id}/end`);
};

export const joinStream = async (id: number) => {
  try {
    await axios.post(`/streamings/${id}/join`);
    // eslint-disable-next-line no-empty
  } catch (error) {}
};

export const leaveStream = async (id: number) => {
  try {
    await axios.post(`/streamings/${id}/leave`);
    // eslint-disable-next-line no-empty
  } catch (error) {}
};

export const fetchStatisticsStream = async (id: number) => {
  return (await axios.get(`/streamings/${id}/statistics`))?.data as {
    data: {
      id: number;
      view_count: number;
      current_viewer_count: number;
      viewer_count: number;
      status: LiveStreamStatus;
      delivery_started_at: string;
    };
  };
};

export const fetchStreamsRemainingTime = async () => {
  return (await axios.get(`/streamings/summary`))?.data as {
    data: StreamRemainingTime;
  };
};
