import { MouseEventHandler, useMemo } from 'react';
import cn from 'classnames';
import {
  externalUrlRedirectLink,
  hashTagRegexFactoryWithSpace,
  linkRegexFactory,
  VALID_EMAIL_REGEX,
} from 'common/utils';
import escapeHtml from 'common/utils/escapeHtml';

import styles from './RichContent.module.scss';
import { Mention } from 'common/interfaces/api';

export type Props = {
  text: string;
  // hack for search result
  specialEscape?: boolean;
  mentionTargets?: Mention[];
};

const specialRegex =
  /&lt;span style=&quot;background:#FFFF00; color:#000; padding:3px;&quot;&gt;(.+)&lt;\/span&gt;/g;
const hashtagRegex = hashTagRegexFactoryWithSpace();
const linkRegex = linkRegexFactory();

const emailReplacer = (match: string) => {
  return `<span class="${styles.email}"><a target="_blank" rel="noopener noreferrer" href="mailto: ${match}">${match}</a></span>`;
};

const linkReplacer = (match: string) => {
  if (VALID_EMAIL_REGEX.test(match)) return emailReplacer(match);
  let href = `//${match}`;
  if (match.startsWith('http') || match.startsWith('https')) {
    href = match;
  }
  return `<a href="${externalUrlRedirectLink(href)}" class="${
    styles.link
  }" target="_blank" rel="noopener noreferrer" onClick="(e) => e.stopPropagation()">${match}</a>`;
};

const hashTagReplacer = (match: string) => {
  const startCharacter = match.substring(0, 1);
  const isStartOfLine = !startCharacter.match(/\s/);
  const showString = isStartOfLine ? match : match.substring(1);
  return `${isStartOfLine ? '' : startCharacter}<span class="${
    styles.hashtag
  }"><a target="_blank" rel="noopener noreferrer" href="/topics/${showString.substring(
    1
  )}">${showString}</a></span>`;
};

export default function RichContent({
  text,
  specialEscape = false,
  mentionTargets = [],
}: Props): JSX.Element {
  const escapedText = useMemo(() => {
    let escaped = escapeHtml(text || '');
    if (specialEscape) {
      escaped = escaped.replace(
        specialRegex,
        `<span class="${styles.special}">$1</span>`
      );
    }
    escaped = escaped.replace(hashtagRegex, hashTagReplacer);
    // because of &#39; has #, should replace after check hash
    escaped = escaped.replace(/'/g, '&#39;');
    mentionTargets.forEach((mention) => {
      escaped = escaped.replace(
        `@${mention.id}`,
        `<a target="_blank" onClick="(e) => e.stopPropagation()" href="/${mention.username}" class="${styles.mention}">@${mention.name}</a>`
      );
    });
    escaped = escaped.replace(linkRegex, linkReplacer);
    return escaped;
  }, [text, specialEscape, mentionTargets]);

  // prevent onClick event when clicking on a link
  const handleClick: MouseEventHandler = (e) => {
    const target = e.target as HTMLElement;
    if (target.tagName.toLowerCase() === 'a') {
      e.stopPropagation();
    }
  };
  return (
    <div
      onClick={handleClick}
      className={cn(styles.richContentWrapper, 'richcontent')}
      dangerouslySetInnerHTML={{
        __html: escapedText,
      }}
    />
  );
}
