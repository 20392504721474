import { ReactElement, useRef, useEffect, useCallback } from 'react';

const ViewedContent = ({
  children,
  firstViewCallback,
}: {
  children: ReactElement;
  firstViewCallback: () => void;
}) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const intersectionCb: IntersectionObserverCallback = useCallback(
    (entries) => {
      const target = entries[0];
      if (target && target.isIntersecting) {
        firstViewCallback();
      }
    },
    [firstViewCallback]
  );

  useEffect(() => {
    if (!contentRef.current) return;
    const ref = contentRef.current;
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.3,
    };
    // Create observer
    const observer = new IntersectionObserver(intersectionCb, options);
    // observe the audio player
    if (ref) {
      observer.observe(ref);
    }
    // clean up on willUnMount
    return () => {
      if (ref) {
        observer.unobserve(ref);
      }
    };
  }, [intersectionCb]);

  return <div ref={contentRef}>{children}</div>;
};

export default ViewedContent;
