import cn from 'classnames';
import { MouseEventHandler } from 'react';

import Icon from 'components/common/Icon';

import styles from './Button.module.scss';

export type Props = {
  text: string;
  onClick?: MouseEventHandler;
  disabled?: boolean;
  icon?: string;
  alternateColor?: boolean;
  alternateColorFaded?: boolean;
  alternateColorWarning?: boolean;
  hasOn?: boolean;
  fadeColorWhenOn?: boolean;
  isOn?: boolean;
  onText?: string;
  blackAndWhite?: boolean;
  smallIcon?: boolean;
  verticalPadding?: number;
  horizontalPadding?: number;
  isLoading?: boolean;
  rounded?: boolean;
  fullWidth?: boolean;
  fadingStyle?: boolean;
  bigGuyStyle?: boolean;
  subText?: string;
  darkOrange?: boolean;
  pink?: boolean;
  colorName?: '' | 'lightRed' | 'pink' | string;
};

export default function BigButton({
  onClick,
  text,
  disabled = false,
  icon,
  alternateColor = false,
  alternateColorFaded = false,
  alternateColorWarning = false,
  darkOrange = false,
  pink = false,
  hasOn = false,
  fadeColorWhenOn = false,
  isOn,
  onText,
  blackAndWhite = false,
  smallIcon = false,
  verticalPadding = 6,
  horizontalPadding = 18,
  isLoading = false,
  rounded = false,
  fullWidth = false,
  fadingStyle = false,
  bigGuyStyle = false,
  subText = '',
  colorName = null,
}: Props): JSX.Element {
  // take into account black and white mode's border
  // so that a button's height is consistent
  const iconSize = smallIcon ? 18 : 32;

  return (
    <button
      className={cn(styles.button, {
        [styles.disabled]: disabled,
        [styles.alternateColor]: alternateColor,
        [styles.alternateColorFaded]: alternateColorFaded,
        [styles.alternateColorWarning]: alternateColorWarning,
        [styles.darkOrange]: darkOrange,
        [styles.pink]: pink,
        [styles.hasIcon]: !!icon,
        [styles.blackAndWhite]: blackAndWhite,
        [styles.faded]: fadeColorWhenOn && isOn,
        [styles.rounded]: rounded,
        [styles.loading]: isLoading,
        [styles.fullWidth]: fullWidth,
        [styles.fadingStyle]: fadingStyle,
        [styles.bigGuyStyle]: bigGuyStyle,
        [styles.hasSubText]: !!subText,
        [styles[colorName]]: !!colorName,
      })}
      disabled={disabled || isLoading}
      onClick={onClick}
      style={
        !subText
          ? {
              padding: `${verticalPadding}px ${horizontalPadding}px`,
            }
          : {}
      }
    >
      {isLoading ? (
        <Icon name="loading-anim-2" width={24} height={24} />
      ) : (
        <>
          <div
            className={styles.main}
            style={
              subText
                ? {
                    padding: `${verticalPadding}px ${horizontalPadding}px`,
                  }
                : {}
            }
          >
            {icon && (
              <div className={styles.icon}>
                <Icon
                  name={icon}
                  width={iconSize}
                  height={iconSize}
                  hasOn={hasOn}
                  isOn={isOn}
                />
              </div>
            )}
            {hasOn && isOn ? onText : text}
          </div>
          {subText && <p className={styles.subText}>{subText}</p>}
        </>
      )}
    </button>
  );
}
