import axios from 'axios';

export enum MediaType {
  Image = 'image',
  Video = 'video',
  Link = 'link',
  Audio = 'audio',
}

export interface NewMedia {
  duration: number;
  height: number;
  media_id: string;
  spectrum: string;
  type: MediaType;
  url: string;
  width: number;
  is_encoding_done: boolean;
}

export const uploadMedia = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  return (
    await axios.post(`/media/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  ).data as {
    data: NewMedia;
  };
};
