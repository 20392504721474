import useFullscreen from '@rooks/use-fullscreen';
import {
  CSSProperties,
  MouseEventHandler,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactPlayer from 'react-player/lazy';
import cn from 'classnames';
import Slider from '@material-ui/core/Slider';
import {
  coinNumberToString,
  DeviceInfo,
  isNumber,
  secondsToNow,
  useDevice,
  useMediaQuery,
} from 'common/utils';
import Icon from 'components/common/Icon';
import { secondsToHms } from 'common/utils';
import useStore from 'store/timeline';
import styles from './LiveStreamPlayer.module.scss';
import useGlobalStore from 'store/global';
import { MembershipPackageName, PostType, User } from 'common/interfaces/api';
import { useRouter } from 'next/router';
import { joinStream, leaveStream } from 'service/livestream';
import { useCallback } from 'react';
import Button from 'components/common/Button';
import useFullscreenStatus from 'common/utils/fullscreen';
import { isGoldMember } from 'common/utils/membership';
import { ScreenName } from 'common/utils/pp_tracking';
import {
  checkIsCreatorPaidPost,
  exCreatorPostLockMessage,
} from 'common/utils/post';

export enum AutoplayThreshold {
  Center,
  Top,
}

export type Props = {
  id: number;
  streamId: number;
  url: string;
  thumbnail: string;
  viewerCount: number;
  postAuthor: User;
  autoplayOnMount?: boolean;
  isLocked?: boolean;
  isPostDetail?: boolean;
  startedAt: string;
  postType: PostType;
  isLiveStreamDetail?: boolean;
  isLive?: boolean;
  refetchToken?: () => void;
  disableAutoPlayOnFocus?: boolean;
  canSeeFreeMinutes?: boolean;
  freeMinutes?: number;
  handleStopFreeTimes?: () => void;
  startPosition: number;
};

export default function LiveStreamPlayer({
  id,
  url,
  streamId,
  viewerCount = 0,
  thumbnail,
  postAuthor,
  autoplayOnMount = false,
  isLocked = false,
  isPostDetail = false,
  postType,
  startedAt,
  isLiveStreamDetail = false,
  isLive = true,
  disableAutoPlayOnFocus = false,
  canSeeFreeMinutes = false,
  freeMinutes = 0,
  handleStopFreeTimes,
  startPosition,
}: Props): JSX.Element {
  const [activeAudioPlayer, setActiveAudioPlayer] = useStore((state) => [
    state.activeAudioPlayer,
    state.setActiveAudioPlayer,
  ]);
  const setSnackbarMessage = useGlobalStore(
    (state) => state.setSnackbarMessage
  );
  const freeTimesRef = useRef<HTMLDivElement>(null);
  const [isMounted, setIsMounted] = useState(false);
  const currentAuth = useGlobalStore((state) => state.currentAuth);
  const currentUser = useStore((state) => state.currentUser);
  const router = useRouter();
  const showLogin = useGlobalStore((state) => state.showLogin);
  const showPaymentDialog = useGlobalStore((state) => state.showPaymentDialog);
  const setShowMembershipPayDialog = useGlobalStore(
    (state) => state.setShowMembershipPayDialog
  );
  const { toggle: toggleFullscreen, isFullscreen } = useFullscreen();
  const playerRef = useRef<ReactPlayer>(null);
  const playerWrapperRef = useRef<HTMLDivElement>(null);
  const [muted, setMuted] = useState(true);
  const [volume, setVolume] = useState(1);
  const [playing, setPlaying] = useState(autoplayOnMount && !isLocked);
  const isMobileScreen = useMediaQuery('(max-width: 767px)');
  const [duration, setDuration] = useState(secondsToNow(new Date(startedAt)));
  const [isReady, setIsReady] = useState(false);
  // const [refetchedTokenCount, setRefetchedTokenCount] = useState(0);
  const [isHover, setIsHover] = useState(false);
  const deviceInfo: DeviceInfo = useDevice();
  const [remainingFreeSeconds, setRemainingFreeSeconds] = useState(0);
  const [isFullScreenBrowser] = useFullscreenStatus();
  const [isBuffering, setIsBuffering] = useState(false);
  // Stop playing audio when other audio sources starts playing
  // Note: how is this working?? why don't playing and muted become stale values??
  useEffect(() => {
    if (activeAudioPlayer?.playing && playing && !muted) {
      setMuted(true);
    }
  }, [activeAudioPlayer, playing, muted]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (!canSeeFreeMinutes || freeMinutes === 0) {
      setRemainingFreeSeconds(0);
      return;
    }
    const countDownFreeTime = setInterval(() => {
      const rmFreeSecs = Math.round(
        Math.max(0, freeMinutes * 60 - secondsToNow(new Date(startedAt)) - 1)
      );
      setRemainingFreeSeconds(rmFreeSecs);
      if (rmFreeSecs === 0) handleStopFreeTimes();
    }, 1000);
    return () => clearInterval(countDownFreeTime);
  }, [canSeeFreeMinutes, freeMinutes, startedAt, handleStopFreeTimes]);

  // Autoplay
  const intersectionCb: IntersectionObserverCallback = useCallback(
    (entries) => {
      let aTimeout, bTImeout;
      if (disableAutoPlayOnFocus) return;
      const target = entries[0];
      if (target && target.isIntersecting) {
        // CENTER: entering the middle 10% of screen height
        // TOP: over 30% of video is shown and user has not intentionally paused the video
        if (!isLocked) {
          aTimeout = setTimeout(() => {
            const light = playerWrapperRef.current?.querySelector(
              '.react-player__preview'
            );
            if (light) {
              (light as HTMLDivElement).click();
            }
          }, 500);
          bTImeout = setTimeout(() => {
            setPlaying(true);
          }, 2000);
        }
      } else {
        // CENTER: leaving the middle 10% of screen height
        // TOP: under 30% of video is shown
        setPlaying(false);
        if (playerRef.current) {
          // This is the key to keeping the player in light mode
          playerRef.current.showPreview();
        }
      }
      return () => {
        aTimeout && clearTimeout(aTimeout);
        bTImeout && clearTimeout(bTImeout);
      };
    },
    [disableAutoPlayOnFocus, isLocked]
  );
  useEffect(() => {
    if (isLocked || isFullscreen || !isMounted) return;
    const ref = playerWrapperRef.current;
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.3,
    };
    // Create observer
    const observer = new IntersectionObserver(intersectionCb, options);
    // observe the audio player
    if (ref) {
      observer.observe(ref);
    }
    // clean up on willUnMount
    return () => {
      if (ref) {
        observer.unobserve(ref);
      }
    };
    // Note: for some reasone playerWrapperRef.current needs to be in the dependency array
    // otherwise initial target.isIntersecting will always be false
  }, [intersectionCb, isLocked, playerWrapperRef, isFullscreen, isMounted]);

  useEffect(() => {
    void joinStream(streamId);
    return () => {
      void leaveStream(streamId);
      setIsBuffering(false);
    };
  }, [streamId]);

  // Media control overlay
  const mediaControlBtn = useMemo(() => {
    let btn: JSX.Element;
    if (!playing) {
      btn = (
        <div className={styles.playWrapper}>
          <div className={styles.centerBtn}>
            <Icon name="play" width={96} height={96} />
          </div>
        </div>
      );
    } else if (!isFullscreen && playing) {
      btn = (
        <div className={cn(styles.playWrapper, styles.pauseBtn)}>
          <div className={styles.centerBtn}>
            <Icon name="pause" width={96} height={96} />
          </div>
        </div>
      );
    }
    return btn;
  }, [isFullscreen, playing]);

  const handleSpeakerBtnClick: MouseEventHandler = (e) => {
    e.stopPropagation();
    setMuted((muted) => !muted);
    if (playing && muted) {
      setActiveAudioPlayer('video', false);
    }
  };

  // Volume
  const handleVolumeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, newVolume: number | number[]) => {
      e.stopPropagation();
      if (!currentAuth || isLocked) return;
      if (!isNumber(newVolume) || Array.isArray(newVolume)) {
        newVolume = 0.5;
      }
      if (newVolume < 0.025) {
        setMuted(true);
      } else {
        setMuted(false);
        setVolume(newVolume);
        if (playing) {
          setActiveAudioPlayer('video', false);
        }
      }
    },
    [currentAuth, isLocked, playing, setActiveAudioPlayer]
  );

  const handleClickFullscreen: MouseEventHandler = (e) => {
    e.stopPropagation();
    if (isLocked) return;

    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (document.exitFullscreen && (document as any).mozFullScreen) {
        void document.exitFullscreen();
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}

    if (!isMobileScreen) {
      void toggleFullscreen(playerWrapperRef.current);
      return;
    }
    /* eslint-disable @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    const elem = (playerRef.current?.wrapper as HTMLDivElement)?.querySelector(
      'video'
    );
    // @ts-ignore
    if (elem && elem.webkitEnterFullScreen) {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      elem.webkitEnterFullScreen();
    } else void toggleFullscreen(playerWrapperRef.current);
    /* eslint-enable @typescript-eslint/ban-ts-comment */
  };

  const stopPropagation: MouseEventHandler = (e) => {
    e.stopPropagation();
  };

  const handleOverlayClick: MouseEventHandler = async (e) => {
    stopPropagation(e);
    if (!isLiveStreamDetail) {
      if (!currentAuth) {
        showLogin({});
        return;
      }
      if (!isPostDetail) {
        await router.push(`/${postAuthor.username}/posts/${id}`);
        return;
      }
      if (isLocked) {
        handleTrialClick();
        return;
      }
    }
    if (freeTimesRef?.current?.contains(e.target as HTMLElement)) return;
    setPlaying((playing) => !playing);
    if (!playing) {
      setActiveAudioPlayer('video', false);
      setMuted(false);
      seekToLast();
    }
  };

  const hms = useMemo(() => {
    return secondsToHms(duration);
  }, [duration]);

  const reactPlayerStyle = useMemo(() => {
    let style: CSSProperties;
    if (isFullscreen || isFullScreenBrowser) {
      style = {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      };
    } else {
      style = {};
    }
    return style;
  }, [isFullScreenBrowser, isFullscreen]);

  const handleTrialClick = () => {
    if (postType === PostType.Gold && !isGoldMember(currentUser)) {
      setShowMembershipPayDialog({
        open: true,
        showMembershipStatus: false,
        initPackage: MembershipPackageName.Platinum,
        screen: ScreenName.PostDetail,
      });
      return;
    }

    if (!postAuthor) return;
    // in case of value is undefined, still can open payment dialog
    if (
      postAuthor.is_open_for_subscription === false &&
      checkIsCreatorPaidPost(postType)
    ) {
      setSnackbarMessage({
        type: 'error',
        text: exCreatorPostLockMessage(postType),
      });
      return;
    }
    showPaymentDialog({ creator: postAuthor });
  };

  useEffect(() => {
    if (isMobileScreen && !isLocked) return;
    if (isHover && !isLocked) {
      const light = playerWrapperRef.current?.querySelector(
        '.react-player__preview'
      );
      if (light) {
        (light as HTMLDivElement).click();
      }
    }
  }, [isHover, isLocked, isMobileScreen]);

  useEffect(() => {
    if (isMobileScreen && !isLocked) return;
    const timeOut = setTimeout(() => {
      const img = new Image();
      img.onload = function () {
        if (!playerWrapperRef.current) return;
        const light = playerWrapperRef.current.querySelector(
          '.react-player__preview'
        );
        const wrapperWidth = playerWrapperRef.current.clientWidth;
        if (light) {
          (light as HTMLDivElement).style.minHeight = `${
            (wrapperWidth / img.width) * img.height
          }px`;
        }
      };
      img.src = thumbnail;
    }, 600);
    return () => clearTimeout(timeOut);
  }, [thumbnail, isMobileScreen, isLocked]);

  const seekToLast = () => {
    if (!playerRef.current) return;
    let playerDuration = playerRef.current.getDuration();
    if (isNaN(playerDuration)) {
      playerDuration = playerRef.current.getDuration();
    }
    if (isNaN(playerDuration)) {
      playerDuration = playerRef.current.getDuration();
    }
    if (isNaN(playerDuration)) {
      if (isNaN(duration)) {
        playerRef.current.seekTo(1, 'fraction');
      } else {
        playerRef.current.seekTo(Math.floor(duration + startPosition - 2));
      }
    } else {
      playerRef.current.seekTo(Math.floor(playerDuration - 1));
    }
  };

  return (
    <div
      className={cn(styles.playerWrapper, {
        [styles.fullscreenVideo]: isFullscreen || isFullScreenBrowser,
      })}
      ref={playerWrapperRef}
      onMouseLeave={() => setIsHover(false)}
      onMouseEnter={() => setIsHover(true)}
    >
      <ReactPlayer
        width="100%"
        height="100%"
        style={reactPlayerStyle}
        ref={playerRef}
        onBuffer={() => setIsBuffering(true)}
        onBufferEnd={() => setIsBuffering(false)}
        onPlay={() => setIsBuffering(false)}
        url={url}
        muted={isPostDetail || isLiveStreamDetail ? muted : true}
        volume={volume}
        playsinline
        playing={playing && isReady && isMounted}
        onStart={() => {
          setTimeout(() => {
            seekToLast();
          }, 1000);
        }}
        onProgress={() => {
          if (!playing) return;
          setDuration(secondsToNow(new Date(startedAt)));
        }}
        onError={() => {
          if (!isReady) return;
          setTimeout(() => {
            setPlaying(true);
          }, 2000);
        }}
        onReady={() => setIsReady(true)}
        light={
          (isMobileScreen || disableAutoPlayOnFocus) && !isLocked
            ? false
            : thumbnail
        }
        config={{
          file: {
            attributes: {
              poster: thumbnail,
            },
            hlsOptions: {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              xhrSetup: function (xhr) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                // xhr.withCredentials = true; // send cookies
              },
              maxLoadingDelay: 4,
              minAutoBitrate: 0,
              lowLatencyMode: true,
              startPosition: startPosition,
            },
          },
        }}
      />
      <div
        className={cn(styles.playerOverlay, {
          [styles.fullscreen]: isFullscreen || isFullScreenBrowser,
          [styles.notPostDetail]: !isPostDetail || !currentAuth,
          [styles.isLiveStreamDetail]: isLiveStreamDetail,
        })}
        onClick={handleOverlayClick}
      >
        {isLive && (
          <div className={styles.streamInfo}>
            <div className={styles.streamLabel}>LIVE</div>
            {isLiveStreamDetail && (
              <div className={styles.viewerCount}>
                <Icon name="eye" width={22} height={22} />
                <span>{coinNumberToString(viewerCount)}</span>
              </div>
            )}
          </div>
        )}
        {isBuffering && (
          <div className={styles.buffering}>
            <Icon name="loading-spinner" width={44} height={44} />
          </div>
        )}
        {canSeeFreeMinutes && isPostDetail && (
          <div className={styles.remainingFreeTime} ref={freeTimesRef}>
            <span>
              残り
              <span className={styles.time}>
                {secondsToHms(Math.max(remainingFreeSeconds, 0))}
              </span>
              プライム投稿に変更されます
            </span>
            <Button
              text="プライム登録を無料体験"
              onClick={(e) => {
                showPaymentDialog({ creator: postAuthor });
                if (isFullscreen || isFullScreenBrowser) {
                  handleClickFullscreen(e);
                }
              }}
            />
          </div>
        )}
        <div className={styles.mediaControlBtn}>{mediaControlBtn}</div>
        <div className={styles.leftCorner}>
          <div className={styles.playTime}>{hms}</div>
        </div>
        <div className={styles.rightCorner}>
          <div className={styles.volumeCtrl} onClick={stopPropagation}>
            {!isMobileScreen && (
              <Slider
                key={`video-slider-${id}`}
                orientation="vertical"
                defaultValue={1}
                min={0}
                max={1}
                step={0.025}
                onChange={handleVolumeChange}
                className={styles.volumeSlider}
              />
            )}
            <div className={styles.speakerBtn} onClick={handleSpeakerBtnClick}>
              <Icon name="speaker" width={26} height={26} hasOn isOn={!muted} />
            </div>
          </div>
          {!isMobileScreen && deviceInfo.isDesktop && (
            <div
              className={styles.fullscreenBtn}
              onClick={handleClickFullscreen}
            >
              <div>
                <Icon name="fullscreen" width={17} height={17} />
              </div>
              <div className={styles.fullscreenText}>
                {isFullscreen ? '全画面を閉じる' : '全画面で見る'}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
