import axios from 'axios';
import { PostParentKey } from 'common/interfaces/api';

export const adsTrackingCall = ({
  type,
  postId,
  parentType,
}: {
  type: 'ads_click' | 'ads_impression';
  parentType: PostParentKey | string;
  postId: number;
}) => {
  try {
    void axios
      .post(`/posts/${postId}/affiliate-interaction`, {
        interaction_type: type,
        client: 'web',
        screen_log: parentType,
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
    // eslint-disable-next-line no-empty
  } catch (error) {}
};
