/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useEffect, useState } from 'react';

export default function useFullscreenStatus() {
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    document.onfullscreenchange = () => {
      const element = getBrowserFullscreenElementProp();
      if (element) {
        setIsFullscreen(document[element] != null);
      }
    };

    return () => (document.onfullscreenchange = undefined);
  });

  return [isFullscreen];
}

const getBrowserFullscreenElementProp = () => {
  if (typeof document.fullscreenElement !== 'undefined') {
    return 'fullscreenElement';
  } else if (typeof (document as any).mozFullScreenElement !== 'undefined') {
    return 'mozFullScreenElement';
  } else if (typeof (document as any).msFullscreenElement !== 'undefined') {
    return 'msFullscreenElement';
  } else if (typeof (document as any).webkitFullscreenElement !== 'undefined') {
    return 'webkitFullscreenElement';
  } else {
    return null;
  }
};
