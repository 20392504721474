import classNames from 'classnames';
import { ALL_PLAYER_RATES } from 'common/utils';
import { canUseOption, isGoldMember } from 'common/utils/membership';
import Icon from 'components/common/Icon';
import useStore from 'store/timeline';
import useGlobalStore from 'store/global';
import styles from './PlayerRateOptions.module.scss';
import {
  addEvent,
  EventAction,
  EventCategory,
  EventComponent,
  ScreenName,
} from 'common/utils/pp_tracking';
import { track } from '@amplitude/analytics-browser';

// FIXME: change isWhite to isAudioPlayer
const PlayerRateOptions = ({
  setPlaybackRate,
  isWhite = false,
  clickCallback,
}: {
  setPlaybackRate: (rate: number) => void;
  isWhite?: boolean;
  clickCallback?: () => void;
}) => {
  const currentUser = useStore((state) => state.currentUser);
  const setShowMembershipPayDialog = useGlobalStore(
    (state) => state.setShowMembershipPayDialog
  );
  const currentAuth = useGlobalStore((state) => state.currentAuth);
  const showLogin = useGlobalStore((state) => state.showLogin);
  return (
    <>
      {ALL_PLAYER_RATES.map((rate, index) => {
        const isVip = rate.isVip && !canUseOption(currentUser, rate.vipPackage);
        return (
          <div
            onClick={() => {
              track(`Change ${isWhite ? 'Audio' : 'Video'} Play Speed`);
              addEvent({
                action: EventAction.Click,
                category: EventCategory.Membership,
                screen: ScreenName.PostDetail,
                component: isWhite
                  ? EventComponent.AudioPlayer
                  : EventComponent.VideoPlayer,
                timestamp: new Date().toISOString(),
                tracking_params: [
                  {
                    label: 'speed_rate',
                    value: rate.rate.toString(),
                  },
                ],
              });

              if (isVip) {
                if (!currentAuth) {
                  showLogin({});
                  return;
                }
                setShowMembershipPayDialog({
                  open: true,
                  showMembershipStatus: false,
                  initPackage: rate.vipPackage,
                  screen: ScreenName.PostDetail,
                });
                clickCallback && clickCallback();
                return;
              }
              setPlaybackRate(rate.rate);
            }}
            className={classNames(styles.text, {
              [styles.isVip]: isVip,
              [styles.isMembership]: isGoldMember(currentUser),
              [styles.isWhite]: isWhite,
            })}
            key={`option-${index}`}
          >
            <div className={styles.content}>{rate.rate}x</div>
            {isVip && (
              <div className={styles.icon}>
                <Icon
                  name={`${rate.vipPackage}-vip-icon`}
                  height={22}
                  width={22}
                />
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default PlayerRateOptions;
