import { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  errorHandler: () => void;
}

interface State {
  hasError: boolean;
}

class ErrorBoundaryWithHandler extends Component<Props, State> {
  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
    this.props.errorHandler();
    this.forceUpdate();
  }

  public render() {
    return this.props.children;
  }
}

export default ErrorBoundaryWithHandler;
