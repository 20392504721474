/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  CompositeDecorator,
  Editor,
  EditorState,
  ContentBlock,
  ContentState,
  DraftHandleValue,
  getDefaultKeyBinding,
} from 'draft-js';
import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import { hashTagRegexFactory, linkRegexFactory } from 'common/utils';

import 'draft-js/dist/Draft.css';
import styles from './TextEditor.module.scss';
import { User } from 'common/interfaces/api';
import { Modifier } from 'draft-js';
import { BlackListUrl, PolicyError } from 'service/post';
import { useCallback } from 'react';
import ErrorBoundaryWithHandler from 'common/utils/ErrorBoundaryClient';

function findWithRegex(
  regex: RegExp,
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void
) {
  const text = contentBlock.getText();
  let matchArr: RegExpExecArray;
  let start: number;
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
}

const mentionSpan = ({ children }) => {
  return <span className={styles.mention}>{children}</span>;
};

const hashtagSpan = ({ children }) => {
  return <span className={styles.hashtag}>{children}</span>;
};

const OverLimitSpan = ({ children }) => {
  return <span className={styles.overLimit}>{children}</span>;
};

const hashtagRegedx = hashTagRegexFactory();
const linkRegex = linkRegexFactory();

function hashtagStrategy(contentBlock, callback) {
  findWithRegex(hashtagRegedx, contentBlock, callback);
}

function linkStrategy(contentBlock, callback) {
  findWithRegex(linkRegex, contentBlock, callback);
}

export type TextEditorRef = {
  getContent: () => string;
  setEditorText: (s: string) => void;
  focus: () => void;
  reset: () => void;
  forceRender: () => void;
};

export type Props = {
  setPlainText: (text: string) => void;
  placeholder?: string;
  onFocus?: () => void;
  readOnly?: boolean;
  initialText?: string;
  charLimit?: number;
  mentionTarget?: User;
  textPolicies?: PolicyError[];
  blackUrls?: BlackListUrl[];
  onEnter?: () => void;
};

const TextEditor = (
  {
    setPlainText,
    placeholder,
    onFocus,
    readOnly,
    initialText = '',
    charLimit = 2000,
    mentionTarget,
    textPolicies = [],
    blackUrls = [],
    onEnter,
  }: Props,
  ref: React.Ref<TextEditorRef>
): JSX.Element => {
  const overLimitStrategy = useMemo(() => {
    return (
      contentBlock: ContentBlock,
      callback: (start: number, end: number) => void
    ) => {
      const text = contentBlock.getText();
      const len = text.length;
      if (len > charLimit) {
        callback(charLimit, len);
      }
    };
  }, [charLimit]);

  const mentionStrategy = useMemo(() => {
    return (
      contentBlock: ContentBlock,
      callback: (start: number, end: number) => void
    ) => {
      if (!mentionTarget) return;
      const text = contentBlock.getText();
      try {
        const regex = new RegExp(
          `@${mentionTarget.name}`
            .replaceAll('(', '\\(')
            .replaceAll(')', '\\)')
            .replaceAll('[', '\\[')
            .replaceAll('*', '\\*')
            .replaceAll('+', '\\+')
        );
        const matchArr = regex.exec(text);
        if (matchArr !== null) {
          const start = matchArr.index;
          callback(start, start + matchArr[0].length);
        }
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };
  }, [mentionTarget]);

  const policyStrategy = useMemo(() => {
    return (
      contentBlock: ContentBlock,
      callback: (start: number, end: number) => void
    ) => {
      if (!textPolicies || textPolicies.length === 0) return;
      const text = contentBlock.getText();
      try {
        textPolicies.forEach((policy) => {
          const regex = new RegExp(policy.name);
          const matchArr = regex.exec(text);
          if (matchArr !== null) {
            const start = matchArr.index;
            callback(start, start + matchArr[0].length);
          }
        });
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };
  }, [textPolicies]);

  const policySpan = useCallback((data: any) => {
    const children = data.children;
    return (
      <span className={styles.policy}>
        <del>{children}</del>
      </span>
    );
  }, []);

  const linkSpan = useCallback(
    (data: any) => {
      const children = data.children;
      if (!blackUrls || blackUrls.length === 0) {
        return <span className={styles.link}>{children}</span>;
      }
      const text = data.decoratedText;
      const blackUrl = blackUrls.find((u) => u.url.includes(text));
      if (!blackUrl) {
        return <span className={styles.link}>{children}</span>;
      }
      return (
        <span className={styles.policy}>
          <del>{children}</del>
        </span>
      );
    },
    [blackUrls]
  );

  const decorator = useMemo(
    () =>
      new CompositeDecorator([
        {
          strategy: overLimitStrategy,
          component: OverLimitSpan,
        },
        {
          strategy: linkStrategy,
          component: linkSpan,
        },
        {
          strategy: mentionStrategy,
          component: mentionSpan,
        },
        {
          strategy: policyStrategy,
          component: policySpan,
        },
        {
          strategy: hashtagStrategy,
          component: hashtagSpan,
        },
      ]),
    [overLimitStrategy, linkSpan, mentionStrategy, policyStrategy, policySpan]
  );

  const [editorState, setEditorState] = useState<EditorState>(() =>
    EditorState.createWithContent(
      ContentState.createFromText(initialText),
      decorator
    )
  );
  const selfRef = useRef<Editor>();

  useImperativeHandle(
    ref,
    () => ({
      getContent() {
        return editorState.getCurrentContent().getPlainText('');
      },
      setEditorText: (s: string) => {
        const state = EditorState.set(
          EditorState.createWithContent(ContentState.createFromText(s)),
          { decorator }
        );
        setEditorState(state);
      },
      focus() {
        setEditorState(EditorState.moveFocusToEnd(editorState));
      },
      reset() {
        const newState = EditorState.createEmpty();
        setEditorState(EditorState.moveFocusToEnd(newState));
      },
      forceRender: function () {
        const content = editorState.getCurrentContent();
        const currentOffset = editorState.getSelection().getFocusOffset();
        const newEditorState = EditorState.createWithContent(
          content,
          decorator
        );
        const newSelection = editorState.getSelection().merge({
          focusOffset: currentOffset,
          anchorOffset: currentOffset,
        });
        setEditorState(
          EditorState.acceptSelection(newEditorState, newSelection)
        );
      },
    }),
    [editorState, decorator]
  );

  useEffect(() => {
    const plainText = editorState.getCurrentContent().getPlainText('');
    setPlainText(plainText);
  }, [setPlainText, editorState]);

  const handleBeforeInput = (
    chars: string,
    state: EditorState
  ): DraftHandleValue => {
    try {
      const currentContentState = state.getCurrentContent();
      const selectionState = state.getSelection();
      setEditorState(
        EditorState.push(
          editorState,
          Modifier.replaceText(currentContentState, selectionState, chars),
          'remove-range'
        )
      );

      // eslint-disable-next-line no-empty
    } catch (error) {}
    return 'handled';
  };

  const handleKeyCommand = (command: string): DraftHandleValue => {
    if (command === 'enter_command') {
      onEnter && onEnter();
      return 'handled';
    }

    return 'not-handled';
  };

  const myKeyBindingFn = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter' && onEnter) {
      return 'enter_command';
    }

    return getDefaultKeyBinding(e);
  };

  return (
    <div className={styles.textEditorWrapper}>
      <ErrorBoundaryWithHandler
        errorHandler={() => {
          const content = editorState.getCurrentContent();
          const currentOffset = editorState.getSelection().getFocusOffset();
          const newEditorState = EditorState.createWithContent(
            content,
            decorator
          );
          const newSelection = editorState.getSelection().merge({
            focusOffset: currentOffset,
            anchorOffset: currentOffset,
          });
          setEditorState(
            EditorState.acceptSelection(newEditorState, newSelection)
          );
        }}
      >
        {editorState && (
          <Editor
            ref={selfRef}
            editorState={editorState}
            onChange={setEditorState}
            placeholder={placeholder}
            spellCheck={true}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={myKeyBindingFn}
            onFocus={onFocus}
            readOnly={readOnly}
            handleBeforeInput={handleBeforeInput}
            handlePastedText={(text) => {
              try {
                const pastedBlocks =
                  ContentState.createFromText(text).getBlockMap();
                const newState = Modifier.replaceWithFragment(
                  editorState.getCurrentContent(),
                  editorState.getSelection(),
                  pastedBlocks
                );
                setEditorState(
                  EditorState.push(editorState, newState, 'insert-fragment')
                );
                // eslint-disable-next-line no-empty
              } catch (error) {}
              return 'handled';
            }}
          />
        )}
      </ErrorBoundaryWithHandler>
    </div>
  );
};

// const policyLink = (text: string, url: string): string => {
//   const start = text.indexOf(`[`);
//   const end = text.indexOf(`]`);
//   const linkText = text.substring(start + 1, end);
//   return text.replace(
//     `[${linkText}]`,
//     `<a onclick="window.open('${url}')">${linkText}</a>`
//   );
// };

export default forwardRef(TextEditor);
