import cn from 'classnames';

import styles from './ProgressRing.module.scss';

export type Props = {
  radius: number;
  current: number;
  total: number;
};

export default function ProgressRing({
  radius,
  current,
  total,
}: Props): JSX.Element {
  const stroke = 3;
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const progress = current > total ? 1 : current / total;
  const strokeDashoffset = circumference - progress * circumference;
  const strokeColor = current <= total ? '#0abc71' : '#e54040';

  return (
    <div
      className={styles.ring}
      style={{
        width: radius * 2,
        height: radius * 2,
      }}
    >
      <div className={cn(styles.text, { [styles.overflow]: current > total })}>
        {total - current}
      </div>
      <svg height={radius * 2} width={radius * 2}>
        <circle
          stroke="#cccccc"
          fill="transparent"
          strokeWidth={stroke}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          stroke={strokeColor}
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={`${circumference} ${circumference}`}
          style={{ strokeDashoffset }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
    </div>
  );
}
