import { isNumber } from 'common/utils';
import { addWatchTimeEvent } from 'common/utils/post';
import create, { SetState, GetState } from 'zustand';

type WatchedMediasType = { [id: string]: number };
type WatchTimeTrackingStore = {
  watchedMedias: WatchedMediasType;
  increaseWatchTimeOfMedia: (id: number) => void;
  stopWatchingMedia: (id: number) => void;
};

const useWatchTimeTracking = create<WatchTimeTrackingStore>(
  (
    set: SetState<WatchTimeTrackingStore>,
    get: GetState<WatchTimeTrackingStore>
  ) => ({
    // states
    watchedMedias: {} as WatchedMediasType,
    increaseWatchTimeOfMedia: (id: number) => {
      const { watchedMedias } = get();
      if (isNumber(watchedMedias[id.toString()])) {
        watchedMedias[id.toString()] = watchedMedias[id.toString()] + 1;
      } else {
        watchedMedias[id.toString()] = 1;
      }
      set({ watchedMedias });
    },
    stopWatchingMedia: (id: number) => {
      const { watchedMedias } = get();
      if (isNumber(watchedMedias[id.toString()])) {
        addWatchTimeEvent({
          postId: id,
          watchTime: watchedMedias[id.toString()],
        });
        delete watchedMedias[id.toString()];
        set({ watchedMedias });
      }
    },
  })
);

export default useWatchTimeTracking;
