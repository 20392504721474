import {
  Language,
  LiveStreamStatus,
  MediaType,
  MembershipPackageName,
  Post,
  PostParentKey,
  PostState,
  PostType,
  User,
} from 'common/interfaces/api';
import {
  AUDIO_TMP_URL,
  externalUrlRedirectLink,
  // coinNumberToString,
  getPostFromUrl,
  loadImageHandleError,
  secondsToNow,
  STREAM_STATISTICS_INTERVAL,
  timeUntilMinutes,
  truncateStr,
} from 'common/utils';
import useStore from 'store/timeline';
import * as portals from 'react-reverse-portal';
import VideoPlayer, { AutoplayThreshold } from 'components/common/VideoPlayer';
import LiveStreamPlayer from 'components/common/LiveStreamPlayer';
import Icon from 'components/common/Icon';
import cn from 'classnames';
import NextImage from 'next/image';
import { useEffect, useRef, useState } from 'react';
import ImagePreviewDialog from 'components/common/ImagePreviewDialog';
import useGlobalStore from 'store/global';
import ViewedContent from 'components/common/ViewedContent';
import styles from './MediaContent.module.scss';
import { adsTrackingCall } from 'service/ads_tracking';
import { useRouter } from 'next/router';
import { useQuery } from 'react-query';
import { fetchStatisticsStream, getStreamToken } from 'service/livestream';
import { useMemo } from 'react';
import dayjs from 'lib/dayjs';
import { useCallback } from 'react';
import BigButton from 'components/common/BigButton';
import {
  handleRegistryNotificationAction,
  handleFollowAction,
} from 'service/user';
import { isGoldMember } from 'common/utils/membership';
import { parentKeyToScreenName, ScreenName } from 'common/utils/pp_tracking';
import {
  addClickPostEvent,
  altImageOfPost,
  checkIsCreatorPaidPost,
  exCreatorPostLockMessage,
} from 'common/utils/post';

const MediaContent = ({
  localPost,
  isContentLocked,
  sharedPost,
  setSharedPost,
  parent,
  isPostDetail = false,
  isMobileScreen,
  isLitePost = false,
  canSeeFreeMinutes,
  setCanSeeFreeMinutes,
  loadedPostWithAuth,
}: {
  localPost: Post;
  isContentLocked: boolean;
  sharedPost?: Post;
  setSharedPost?: (post: Post) => void;
  parent: PostParentKey | string;
  isPostDetail?: boolean;
  isMobileScreen: boolean;
  isLitePost?: boolean;
  canSeeFreeMinutes: boolean;
  setCanSeeFreeMinutes: (canSee: boolean) => void;
  loadedPostWithAuth?: boolean;
}): JSX.Element => {
  if (localPost.streaming) {
    return (
      <div
        className={cn(styles.mediaHeader, {
          [styles.isPostDetail]: isPostDetail,
        })}
      >
        <MediaLiveStream
          localPost={localPost}
          isMobileScreen={isMobileScreen}
          isContentLocked={isContentLocked}
          isPostDetail={isPostDetail}
          canSeeFreeMinutes={canSeeFreeMinutes}
          setCanSeeFreeMinutes={setCanSeeFreeMinutes}
          loadedPostWithAuth={loadedPostWithAuth}
          parent={parent}
        />
      </div>
    );
  }

  if (!localPost.media || !localPost.media[0]) return <></>;
  const postContent = () => {
    switch (localPost.media[0].type) {
      case MediaType.Video:
        return (
          <MediaVideo
            localPost={localPost}
            isContentLocked={isContentLocked}
            isMobileScreen={isMobileScreen}
            isPostDetail={isPostDetail}
            canSeeFreeMinutes={canSeeFreeMinutes}
            setCanSeeFreeMinutes={setCanSeeFreeMinutes}
            parent={parent}
          />
        );
      case MediaType.Image:
        return (
          <MediaImage
            localPost={localPost}
            isContentLocked={isContentLocked}
            isMobileScreen={isMobileScreen}
            isPostDetail={isPostDetail}
            parent={parent}
          />
        );
      case MediaType.Link:
        if (localPost.is_affiliate)
          return (
            <MediaAds
              localPost={localPost}
              parent={parent}
              isPostDetail={isPostDetail}
            />
          );
        return (
          <MediaLink
            localPost={localPost}
            sharedPost={sharedPost}
            setSharedPost={setSharedPost}
            isLitePost={isLitePost}
          />
        );
      case MediaType.Audio:
        return (
          <MediaAudio
            localPost={localPost}
            isContentLocked={isContentLocked}
            parent={parent}
            isPostDetail={isPostDetail}
            canSeeFreeMinutes={canSeeFreeMinutes}
            setCanSeeFreeMinutes={setCanSeeFreeMinutes}
          />
        );
      default:
        return <></>;
    }
  };
  return (
    <div
      className={cn(styles.mediaHeader, {
        [styles.isPostDetail]: isPostDetail,
      })}
    >
      {postContent()}
    </div>
  );
};

const MediaVideo = ({
  localPost,
  isContentLocked,
  isMobileScreen,
  isPostDetail = false,
  canSeeFreeMinutes = false,
  setCanSeeFreeMinutes,
  parent,
}: {
  localPost: Post;
  isContentLocked: boolean;
  isMobileScreen: boolean;
  isPostDetail: boolean;
  canSeeFreeMinutes: boolean;
  setCanSeeFreeMinutes: (canSee: boolean) => void;
  parent: PostParentKey | string;
}) => {
  const currentAuth = useGlobalStore((state) => state.currentAuth);
  const currentUser = useStore((state) => state.currentUser);
  const setShowMembershipPayDialog = useGlobalStore(
    (state) => state.setShowMembershipPayDialog
  );
  const showLogin = useGlobalStore((state) => state.showLogin);
  const setSnackbarMessage = useGlobalStore(
    (state) => state.setSnackbarMessage
  );
  const [isScale, setIsScale] = useState(false);
  const showPaymentDialog = useGlobalStore((state) => state.showPaymentDialog);
  const router = useRouter();
  const postDetailsUrl = `/${localPost.user_info?.username}/posts/${localPost.id}`;
  const isVideoLocked = isContentLocked && !canSeeFreeMinutes;
  const showingVideo =
    !isVideoLocked &&
    !(isMobileScreen && !isPostDetail) &&
    localPost.status === PostState.Activated;

  useEffect(() => {
    setCanSeeFreeMinutes(
      currentAuth &&
        isContentLocked &&
        localPost.free_minutes > 0 &&
        localPost.media[0].data.free_version &&
        !!localPost.media[0].data.duration
    );
  }, [
    currentAuth,
    isContentLocked,
    setCanSeeFreeMinutes,
    localPost.free_minutes,
    localPost.media,
  ]);

  const handleStopFreeTimes = () => {
    setCanSeeFreeMinutes(false);
  };

  return (
    <div
      className={cn(styles.mediaWrapper, styles.isVideo, {
        [styles.scaleMaxHeight]: isScale && !showingVideo,
      })}
    >
      {showingVideo ? (
        <VideoPlayer
          id={localPost.id}
          url={localPost.media[0].data.url}
          thumbnail={localPost.media[0].data.thumbnail_url}
          postAuthor={localPost.user_info}
          isLocked={isVideoLocked}
          postState={localPost.status}
          isPostDetail={isPostDetail}
          autoplayOnMount={isPostDetail}
          autoplayThreshold={AutoplayThreshold.Center}
          disableAutoPlayOnFocus={isPostDetail}
          postType={localPost.type}
          canSeeFreeMinutes={canSeeFreeMinutes}
          freeUrl={localPost.media[0].data.free_version}
          freeMinutes={localPost.free_minutes}
          handleStopFreeTimes={handleStopFreeTimes}
          fullDuration={localPost.media[0].data.duration}
          parent={parent}
          postSource={localPost.source}
        />
      ) : (
        <a
          onClick={() => {
            if (!isPostDetail) {
              void router.push(postDetailsUrl);
              if (!isPostDetail) {
                addClickPostEvent({
                  postId: localPost.id,
                  screen: parentKeyToScreenName(parent) as ScreenName,
                  postSource: localPost.source,
                  clickPosition: 'media_view',
                  postType: localPost.type,
                  mediaType: MediaType.Video,
                });
              }
              return;
            }
            if (!currentAuth) {
              showLogin({ redirectUrl: postDetailsUrl });
              return;
            }
            if (localPost.status === PostState.OnProcessing) {
              setSnackbarMessage({
                type: 'warning',
                text: '処理中のビデオを再生できません',
                autoHideDuration: 6000,
              });
              return;
            }
            if (
              isContentLocked &&
              localPost.type === PostType.Gold &&
              !isGoldMember(currentUser)
            ) {
              setShowMembershipPayDialog({
                open: true,
                showMembershipStatus: false,
                initPackage: MembershipPackageName.Platinum,
                screen: ScreenName.PostDetail,
              });
              return;
            }
            if (
              isContentLocked &&
              isPostDetail &&
              checkIsCreatorPaidPost(localPost.type)
            ) {
              if (localPost.user_info.is_open_for_subscription === false) {
                setSnackbarMessage({
                  type: 'error',
                  text: exCreatorPostLockMessage(localPost.type),
                });
                return;
              }
              showPaymentDialog({ creator: localPost.user_info });
              return;
            }
          }}
        >
          <div className={styles.mediaControlBtn}>
            <Icon name="play" width={96} height={96} />
          </div>
          <img
            loading="lazy"
            src={localPost.media[0].data.thumbnail_url}
            alt={altImageOfPost(localPost.text)}
            onLoad={(e) => {
              const img = e.target as HTMLImageElement;
              if (!img) return;
              if (img.naturalHeight > img.naturalWidth / 1.2) {
                setIsScale(true);
              }
            }}
          />
          {localPost.status === PostState.OnProcessing && (
            <div className={styles.processingLabel}>
              <Icon name="waiting" width={44} height={44} />
              <div className={styles.text}>
                処理中のため、少しお待ちください。
              </div>
            </div>
          )}
        </a>
      )}
    </div>
  );
};

const MediaImage = ({
  localPost,
  isContentLocked,
  isMobileScreen,
  isPostDetail,
  parent,
}: {
  localPost: Post;
  isContentLocked: boolean;
  isMobileScreen: boolean;
  isPostDetail: boolean;
  parent: PostParentKey | string;
}): JSX.Element => {
  // Image preview dialog
  const [imgSrc, setImgSrc] = useState<string>(null);
  const [openPreview, setOpenPreview] = useState(false);
  const currentAuth = useGlobalStore((state) => state.currentAuth);
  const showLogin = useGlobalStore((state) => state.showLogin);
  const router = useRouter();
  const postDetailsUrl = `/${localPost.user_info?.username}/posts/${localPost.id}`;
  const handleOpenClick = (imgSrc: string) => {
    if (!isPostDetail) {
      addClickPostEvent({
        postId: localPost.id,
        screen: parentKeyToScreenName(parent) as ScreenName,
        postSource: localPost.source,
        clickPosition: 'media_view',
        postType: localPost.type,
        mediaType: MediaType.Image,
      });
      void router.push(postDetailsUrl);
      return;
    }
    if (!currentAuth) {
      showLogin({});
      return;
    }
    if (!isContentLocked) {
      setImgSrc(imgSrc);
      setOpenPreview(true);
    }
  };
  let isScaleMaxHeight = false;
  if (isMobileScreen) {
    isScaleMaxHeight =
      localPost.media[0].data.height > localPost.media[0].data.width / 0.8;
  } else {
    isScaleMaxHeight =
      localPost.media[0].data.height > localPost.media[0].data.width / 1.2;
  }
  const isBlur = isContentLocked && !!localPost.media[0].data.blur;
  return (
    <>
      <div
        className={cn(styles.mediaWrapper, styles.imageMedia, {
          [styles.scaleMaxHeight]: isScaleMaxHeight,
        })}
        onClick={() => handleOpenClick(localPost.media[0].data.url)}
        onContextMenu={(e) => e.preventDefault()}
      >
        <div
          className={cn(styles.media, {
            [styles.blurred]: isBlur,
          })}
        >
          <div className={styles.imageWrapper}>
            <NextImage
              src={localPost.media[0].data.url}
              layout="responsive"
              priority={isPostDetail}
              height={localPost.media[0].data.height}
              width={localPost.media[0].data.width}
              alt={altImageOfPost(localPost.text)}
            />
          </div>
        </div>
      </div>
      {openPreview && (
        <ImagePreviewDialog
          imgSrc={imgSrc}
          open={openPreview}
          setOpen={setOpenPreview}
        />
      )}
    </>
  );
};

const MediaLink = ({
  localPost,
  sharedPost,
  setSharedPost,
  isLitePost,
}: {
  localPost: Post;
  sharedPost: Post;
  setSharedPost: (post: Post) => void;
  isLitePost: boolean;
}): JSX.Element => {
  const linkThumbnailRef = useRef<HTMLImageElement>(null);
  const [noThumbnail, setNoThumbnail] = useState(false);
  const [isScale, setIsScale] = useState(false);
  useEffect(() => {
    if (isLitePost) return;
    const fetchSharePost = async (url: string): Promise<void> => {
      const sharedPost: Post = await getPostFromUrl(url);
      if (sharedPost) {
        setSharedPost && setSharedPost(sharedPost);
      } else {
        setSharedPost && setSharedPost(null);
      }
    };
    const linkUrl = localPost.media[0].data?.url;
    if (!linkUrl) return;
    void fetchSharePost(linkUrl);
  }, [localPost, setSharedPost, isLitePost]);

  if (sharedPost) return <></>;
  return (
    <div
      className={cn(styles.mediaWrapper, styles.linkMedia, {
        [styles.linkMediaNoThumbnail]: noThumbnail,
        [styles.scaleMaxHeight]: isScale,
      })}
    >
      <a
        href={externalUrlRedirectLink(localPost.media[0].data.url)}
        target="_blank"
        rel="noopener"
        style={{ width: '100%' }}
      >
        <div className={styles.mediaContainer}>
          <img
            className={styles.media}
            loading="lazy"
            src={localPost.media[0].data.thumbnail_url}
            ref={linkThumbnailRef}
            alt={altImageOfPost(localPost.text)}
            onLoad={(e) => {
              const img = e.target as HTMLImageElement;
              if (!img) return;
              if (img.naturalHeight > img.naturalWidth / 1.2) {
                setIsScale(true);
              }
            }}
            onError={() =>
              loadImageHandleError(
                localPost.media[0].data.thumbnail_url,
                linkThumbnailRef,
                () => {
                  linkThumbnailRef.current.style.display = 'none';
                  setNoThumbnail(true);
                }
              )
            }
          />

          <div className={styles.linkInfo}>
            <div className={styles.linkTitle}>
              {truncateStr(localPost.media[0].data.title, 60)}
            </div>
            <div className={styles.bottomLeft}>
              <div className={styles.linkSrc}>
                {localPost.media[0].data.source_name}
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

const MediaAudio = ({
  localPost,
  isContentLocked,
  parent,
  isPostDetail = false,
  canSeeFreeMinutes,
  setCanSeeFreeMinutes,
}: {
  localPost: Post;
  isContentLocked: boolean;
  parent: PostParentKey | string;
  isPostDetail: boolean;
  canSeeFreeMinutes: boolean;
  setCanSeeFreeMinutes: (canSee: boolean) => void;
}): JSX.Element => {
  const portalNodes = useGlobalStore((state) => state.portalNodes);
  const postKey = parent + localPost.id.toString();
  const currentAuth = useGlobalStore((state) => state.currentAuth);
  // Set up audio player
  const setAudioPlayer = useGlobalStore((state) => state.setAudioPlayer);
  const activeAudioId = useStore((state) => state.activeAudioPlayer?.id);
  const activeAudioIdRef = useRef<string>(null);
  activeAudioIdRef.current = activeAudioId;

  useEffect(() => {
    setAudioPlayer(postKey, {
      id: localPost.id,
      url: localPost.media[0].data.url || AUDIO_TMP_URL,
      trackTitle: localPost.media[0].data.title,
      postAuthor: localPost.user_info,
      removed: false,
      parent: parent,
      spectrum: localPost.media[0].data.spectrum,
      duration: localPost.media[0].data.duration,
      thumbnailUrl: localPost.media[0].data.thumbnail_url,
      canSeeFreeMinutes: canSeeFreeMinutes,
      isLocked: isContentLocked,
      freeMinutes: localPost.free_minutes,
      freeUrl: localPost.media[0].data.free_version,
      isPostDetail: isPostDetail,
      postType: localPost.type,
      postSource: localPost.source,
    });
    return () => {
      if (postKey !== activeAudioIdRef.current) setAudioPlayer(postKey, null);
      else
        setAudioPlayer(postKey, {
          removed: true,
        });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    localPost.media,
    localPost.free_minutes,
    localPost.id,
    postKey,
    setAudioPlayer,
    parent,
    canSeeFreeMinutes,
    localPost.user_info,
    isContentLocked,
    isPostDetail,
    localPost.type,
  ]);

  useEffect(() => {
    setCanSeeFreeMinutes(
      currentAuth &&
        isContentLocked &&
        localPost.free_minutes > 0 &&
        localPost.media[0].data.free_version &&
        !!localPost.media[0].data.duration
    );
  }, [
    isContentLocked,
    setCanSeeFreeMinutes,
    localPost.free_minutes,
    localPost.media,
    currentAuth,
  ]);

  if (!portalNodes[postKey]) return <></>;
  return (
    <div className={styles.mediaWrapper}>
      <portals.OutPortal node={portalNodes[postKey]} />
    </div>
  );
};

const MediaAds = ({
  localPost,
  parent,
  isPostDetail = false,
}: {
  localPost: Post;
  parent: PostParentKey | string;
  isPostDetail?: boolean;
}): JSX.Element => {
  const linkThumbnailRef = useRef<HTMLImageElement>(null);
  const [noThumbnail, setNoThumbnail] = useState(false);
  const [isScale, setIsScale] = useState(false);

  const handleFirstImpression = () => {
    if (isPostDetail) return;
    adsTrackingCall({
      type: 'ads_impression',
      parentType: parent,
      postId: localPost.id,
    });
  };

  const handleClickLinkAds = () => {
    adsTrackingCall({
      type: 'ads_click',
      parentType: parent,
      postId: localPost.id,
    });
  };

  return (
    <ViewedContent firstViewCallback={handleFirstImpression}>
      <div
        className={cn(styles.mediaWrapper, styles.linkMedia, styles.adsPost, {
          [styles.linkMediaNoThumbnail]: noThumbnail,
          [styles.scaleMaxHeight]: isScale,
        })}
      >
        <a
          href={externalUrlRedirectLink(localPost.media[0].data.url)}
          target="_blank"
          rel="noopener"
          style={{ width: '100%' }}
          onClick={() => handleClickLinkAds()}
        >
          <div className={styles.mediaContainer}>
            <img
              className={styles.media}
              loading="lazy"
              src={localPost.media[0].data.thumbnail_url}
              ref={linkThumbnailRef}
              onLoad={(e) => {
                const img = e.target as HTMLImageElement;
                if (!img) return;
                if (img.naturalHeight > img.naturalWidth / 1.2) {
                  setIsScale(true);
                }
              }}
              alt={altImageOfPost(localPost.text)}
              onError={() =>
                loadImageHandleError(
                  localPost.media[0].data.thumbnail_url,
                  linkThumbnailRef,
                  () => {
                    linkThumbnailRef.current.style.display = 'none';
                    setNoThumbnail(true);
                  }
                )
              }
            />

            <div className={styles.linkInfo}>
              <div className={styles.linkTitle}>
                {localPost.media[0].data.title}
              </div>
            </div>
          </div>
        </a>
      </div>
    </ViewedContent>
  );
};

const RegistryNotification = ({ user }: { user: User }) => {
  const refetchCurrentProfile = useStore(
    (state) => state.refetchCurrentProfile
  );
  const setSnackbarMessage = useGlobalStore(
    (state) => state.setSnackbarMessage
  );
  const currentUser = useStore((state) => state.currentUser);
  const [done, setDone] = useState(false);
  if (
    done ||
    !user ||
    !currentUser ||
    currentUser.id === user.id ||
    !user.is_turn_on_notification_btn
  )
    return <></>;
  const isFollow = user.is_followed;

  return (
    <div className={styles.notifyBtn}>
      <BigButton
        text="通知ON"
        onText="通知OFF"
        // icon="bell-follow-orange"
        fadeColorWhenOn
        // alternateColor
        fullWidth
        // bigGuyStyle
        onClick={async (e) => {
          try {
            e.preventDefault();
            if (!isFollow) {
              await handleFollowAction(user.id, 'follow');
            }
            await handleRegistryNotificationAction(user.username, 'post');
            refetchCurrentProfile();
            setDone(true);
            setSnackbarMessage({
              type: 'success',
              text: '通知をONにしました',
            });
          } catch (error) {
            setDone(true);
          }
        }}
      />
    </div>
  );
};

export const MediaLiveStream = ({
  localPost,
  isContentLocked,
  isPostDetail = false,
  isMobileScreen,
  canSeeFreeMinutes = false,
  setCanSeeFreeMinutes,
  loadedPostWithAuth,
  parent,
}: {
  localPost: Post;
  isContentLocked: boolean;
  isPostDetail: boolean;
  isMobileScreen: boolean;
  canSeeFreeMinutes: boolean;
  setCanSeeFreeMinutes: (canSee: boolean) => void;
  loadedPostWithAuth: boolean;
  parent: PostParentKey | string;
}) => {
  const currentAuth = useGlobalStore((state) => state.currentAuth);
  const isAuthInit = useGlobalStore((state) => state.isAuthInit);
  const showLogin = useGlobalStore((state) => state.showLogin);
  const setSnackbarMessage = useGlobalStore(
    (state) => state.setSnackbarMessage
  );
  const currentUser = useStore((state) => state.currentUser);
  const setShowMembershipPayDialog = useGlobalStore(
    (state) => state.setShowMembershipPayDialog
  );
  const [isScale, setIsScale] = useState(false);
  const [viewerCount, setViewerCount] = useState(0);
  const showPaymentDialog = useGlobalStore((state) => state.showPaymentDialog);
  const refetchPost = useStore((state) => state.refetchPost);
  const router = useRouter();
  const linkThumbnailRef = useRef<HTMLImageElement>(null);
  const streamData = localPost.streaming;
  const isStreaming =
    streamData?.status === LiveStreamStatus.InLive && !!streamData?.path_hls;
  const beforeLiveRef = useRef<HTMLDivElement>();
  useEffect(() => {
    setCanSeeFreeMinutes(
      currentAuth &&
        isStreaming &&
        isContentLocked &&
        localPost?.free_minutes > 0 &&
        !!streamData?.delivery_started_at &&
        secondsToNow(new Date(streamData.delivery_started_at)) <
          localPost?.free_minutes * 60
    );
  }, [
    currentAuth,
    isContentLocked,
    isStreaming,
    setCanSeeFreeMinutes,
    streamData.delivery_started_at,
    localPost?.free_minutes,
  ]);

  const handleStopFreeTimes = () => {
    setCanSeeFreeMinutes(false);
  };

  const showingThumbnail =
    !currentAuth ||
    (isMobileScreen && !isPostDetail) ||
    ((isContentLocked || !isStreaming) && !canSeeFreeMinutes);
  const [noThumbnail, setNoThumbnail] = useState(false);
  const planStartTime =
    streamData?.planned_to_start_at || new Date().toISOString();

  const fetchStreamData = useCallback(async () => {
    if (!isAuthInit) return;
    try {
      const statistics = (await fetchStatisticsStream(streamData?.id))?.data;
      setViewerCount(statistics?.current_viewer_count);
      if (!currentAuth) return;
      if (
        !isStreaming &&
        statistics.status === LiveStreamStatus.InLive &&
        statistics.delivery_started_at
      ) {
        setTimeout(() => {
          refetchPost();
        }, 5000);
      } else if (statistics.status === LiveStreamStatus.Ended) {
        setTimeout(() => {
          refetchPost();
        }, 10000);
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }, [isAuthInit, streamData?.id, currentAuth, isStreaming, refetchPost]);

  useEffect(() => {
    if (!fetchStreamData || !streamData?.id || !isPostDetail) return;
    if (viewerCount === 0) {
      void fetchStreamData();
    }
    const fetchStatistics = setInterval(() => {
      void fetchStreamData();
    }, STREAM_STATISTICS_INTERVAL);
    return () => clearInterval(fetchStatistics);
  }, [
    fetchStreamData,
    isStreaming,
    viewerCount,
    streamData?.id,
    isPostDetail,
    isContentLocked,
  ]);

  // waiting backend
  const thumbnailShow = useMemo(() => {
    return (
      <div
        className={styles.liveStreamThumbnail}
        onClick={(e) => {
          e.preventDefault();
          if (beforeLiveRef?.current?.contains(e.target as HTMLElement)) return;
          const postDetailsUrl = `/${localPost.user_info?.username}/posts/${localPost.id}`;
          if (!isPostDetail) {
            addClickPostEvent({
              postId: localPost.id,
              screen: parentKeyToScreenName(parent) as ScreenName,
              postSource: localPost.source,
              clickPosition: 'media_view',
              postType: localPost.type,
              mediaType: MediaType.Stream,
            });
            void router.push(postDetailsUrl);
            return;
          }
          if (!currentAuth) {
            showLogin({ redirectUrl: postDetailsUrl });
            return;
          }
          if (
            isContentLocked &&
            localPost.type === PostType.Gold &&
            !isGoldMember(currentUser)
          ) {
            setShowMembershipPayDialog({
              open: true,
              showMembershipStatus: false,
              initPackage: MembershipPackageName.Platinum,
              screen: ScreenName.PostDetail,
            });
            return;
          }
          if (
            isPostDetail &&
            isContentLocked &&
            checkIsCreatorPaidPost(localPost.type)
          ) {
            if (localPost.user_info.is_open_for_subscription === false) {
              setSnackbarMessage({
                type: 'error',
                text: exCreatorPostLockMessage(localPost.type),
              });
              return;
            }
            showPaymentDialog({ creator: localPost.user_info });
            return;
          }
        }}
      >
        {isStreaming && (
          <div className={styles.mediaControlBtn}>
            <Icon name="play" width={96} height={96} />
          </div>
        )}
        <img
          loading="lazy"
          src={streamData?.thumbnail}
          alt={altImageOfPost(localPost.text)}
          onLoad={(e) => {
            const target = e.target as HTMLImageElement;
            if (!target) return;
            if (target.naturalHeight > target.naturalWidth / 1.2) {
              setIsScale(true);
            }
          }}
          onError={() =>
            loadImageHandleError(
              streamData?.thumbnail,
              linkThumbnailRef,
              () => {
                linkThumbnailRef.current.style.display = 'none';
                setNoThumbnail(true);
              }
            )
          }
        />
        {isStreaming && (
          <div className={styles.streamInfo}>
            <div className={styles.streamLabel}>LIVE</div>
            {/* <div className={styles.viewerCount}>
              <Icon name="eye" width={22} height={22} />
              <span>
                {coinNumberToString(
                  isPostDetail
                    ? viewerCount
                    : streamData?.statistic?.current_viewer_count || 0
                )}
              </span>
            </div> */}
          </div>
        )}
      </div>
    );
  }, [
    isStreaming,
    streamData?.thumbnail,
    localPost.user_info,
    localPost.id,
    localPost.type,
    localPost.source,
    localPost.text,
    isPostDetail,
    currentAuth,
    isContentLocked,
    currentUser,
    parent,
    router,
    showLogin,
    setShowMembershipPayDialog,
    showPaymentDialog,
    setSnackbarMessage,
  ]);
  const { data: tokenData } = useQuery(
    ['getLiveStreamToken', localPost.id.toString()],
    async () => {
      return getStreamToken(localPost.streaming?.id);
    },
    {
      enabled: isAuthInit && !!currentAuth && isStreaming,
      refetchOnWindowFocus: false,
    }
  );
  if (!streamData) return <></>;
  if (!localPost.user_info) return <></>;
  const token = streamData?.token || tokenData?.data?.token || '';

  return (
    <>
      <div
        className={cn(styles.mediaWrapper, styles.isStreaming, {
          [styles.linkMediaNoThumbnail]: noThumbnail,
          [styles.scaleMaxHeight]: isScale && showingThumbnail,
        })}
      >
        {showingThumbnail || !token || (isPostDetail && !loadedPostWithAuth) ? (
          thumbnailShow
        ) : (
          <div className={styles.videoWrapper}>
            <LiveStreamPlayer
              id={localPost.id}
              disableAutoPlayOnFocus={isPostDetail}
              startPosition={streamData?.dvr_start_position || 0}
              viewerCount={
                isPostDetail
                  ? viewerCount
                  : streamData?.statistic?.current_viewer_count
              }
              streamId={streamData?.id}
              url={`${streamData?.path_hls}?token=${token}`}
              isLive={true}
              startedAt={streamData?.delivery_started_at}
              canSeeFreeMinutes={canSeeFreeMinutes}
              freeMinutes={localPost?.free_minutes}
              thumbnail={streamData?.thumbnail}
              postAuthor={localPost.user_info}
              isLocked={isContentLocked && !canSeeFreeMinutes}
              isPostDetail={isPostDetail}
              autoplayOnMount={isPostDetail}
              handleStopFreeTimes={handleStopFreeTimes}
              postType={localPost.type}
            />
          </div>
        )}
      </div>
      {(showingThumbnail || !token) && !isStreaming && (
        <div className={styles.beforeLiveWrapper} ref={beforeLiveRef}>
          <Icon name="loading-spinner" width={44} height={44} />
          <div className={styles.timeWrapper}>
            <div className={styles.text}>
              {new Date(planStartTime) >= new Date()
                ? `LIVE開始まで
                ${timeUntilMinutes(new Date(planStartTime), Language.Ja)}`
                : `LIVE開始を待っています`}
            </div>
            <div className={styles.time}>
              {dayjs(planStartTime).format('開始予定時間 MM月DD日 HH:mm')}
            </div>
          </div>
          <div>
            <RegistryNotification user={localPost.user_info} />
          </div>
        </div>
      )}
    </>
  );
};

export const MediaLiveStreamCreator = ({
  localPost,
  isLive = true,
}: {
  localPost: Post;
  isLive?: boolean;
}) => {
  const isAuthInit = useGlobalStore((state) => state.isAuthInit);
  const currentAuth = useGlobalStore((state) => state.currentAuth);
  const streamData = localPost.streaming;
  const [isScale, setIsScale] = useState(false);
  const isStreaming =
    streamData?.status === LiveStreamStatus.InLive && !!streamData?.path_hls;
  const { data: tokenData, refetch: refetchToken } = useQuery(
    ['getLiveStreamToken', localPost.id.toString()],
    async () => {
      return getStreamToken(localPost.streaming?.id);
    },
    {
      enabled: isAuthInit && !!currentAuth,
      refetchOnWindowFocus: false,
    }
  );
  const [viewerCount, setViewerCount] = useState(0);
  const planStartTime =
    streamData?.planned_to_start_at || new Date().toISOString();
  const fetchStreamData = useCallback(async () => {
    try {
      const statistics = (await fetchStatisticsStream(streamData?.id))?.data;
      setViewerCount(statistics?.current_viewer_count);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }, [streamData?.id]);

  useEffect(() => {
    if (!isStreaming || !fetchStreamData || !streamData?.id) return;
    if (viewerCount === 0) {
      void fetchStreamData();
    }
    const fetchStatistics = setInterval(() => {
      void fetchStreamData();
    }, STREAM_STATISTICS_INTERVAL);
    return () => clearInterval(fetchStatistics);
  }, [fetchStreamData, isStreaming, viewerCount, streamData?.id]);

  // waiting backend
  const thumbnailShow = useMemo(() => {
    return (
      <div className={styles.liveStreamThumbnail}>
        {isStreaming && (
          <div className={styles.mediaControlBtn}>
            <Icon name="play" width={96} height={96} />
          </div>
        )}
        <img
          loading="lazy"
          src={streamData?.thumbnail}
          alt="thumbnail"
          onLoad={(e) => {
            const target = e.target as HTMLImageElement;
            if (!target) return;
            if (target.naturalHeight > target.naturalWidth / 1.2) {
              setIsScale(true);
            }
          }}
        />
        {isStreaming && (
          <div className={styles.streamInfo}>
            <div className={styles.streamLabel}>LIVE</div>
          </div>
        )}

        {!isStreaming && (
          <div className={styles.beforeLiveLabel}>
            <Icon name="loading-spinner" width={44} height={44} />
            <div className={styles.timeWrapper}>
              <div className={styles.text}>
                {new Date(planStartTime) >= new Date()
                  ? `LIVE開始まで
                ${timeUntilMinutes(new Date(planStartTime), Language.Ja)}`
                  : `LIVE開始を待っています`}
              </div>
              <div className={styles.time}>
                {dayjs(planStartTime).format('開始予定時間 MM月DD日 HH:mm')}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }, [isStreaming, planStartTime, streamData?.thumbnail]);

  if (!streamData) return <></>;
  if (!localPost.user_info) return <></>;
  const token = streamData?.token || tokenData?.data?.token || '';
  return (
    <div className={styles.mediaHeader} style={{ width: '100%' }}>
      <div
        className={cn(styles.mediaWrapper, {
          [styles.scaleMaxHeight]: isScale,
        })}
      >
        {!isStreaming || !token ? (
          thumbnailShow
        ) : (
          <div className={styles.videoWrapper}>
            <LiveStreamPlayer
              id={localPost.id}
              streamId={streamData?.id}
              disableAutoPlayOnFocus
              startPosition={streamData?.dvr_start_position || 0}
              viewerCount={viewerCount}
              url={`${localPost.streaming?.path_hls}?token=${token}`}
              isLive={isLive}
              startedAt={
                isLive
                  ? streamData?.delivery_started_at || streamData?.started_at
                  : streamData?.started_at
              }
              thumbnail={streamData?.thumbnail}
              postAuthor={localPost.user_info}
              isLiveStreamDetail={true}
              isLocked={false}
              isPostDetail={false}
              autoplayOnMount={true}
              refetchToken={refetchToken}
              postType={localPost.type}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MediaContent;
