import { Dispatch, MouseEventHandler } from 'react';
import Dialog from '@material-ui/core/Dialog';

import Icon from 'components/common/Icon';

import styles from './ImagePreviewDialog.module.scss';

export type Props = {
  imgSrc: string;
  open: boolean;
  setOpen: Dispatch<boolean>;
};

export default function ImagePreviewDialog({
  imgSrc,
  open,
  setOpen,
}: Props): JSX.Element {
  // Close the dialog when clicking anywhere except for the image
  const ignoreAction: MouseEventHandler = (e) => {
    e.stopPropagation();
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => setOpen(false)}
      onClick={() => setOpen(false)}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
    >
      <div className={styles.dialogWrapper}>
        <div className={styles.closeBtn} onClick={() => setOpen(false)}>
          <Icon name="close" width={32} height={32} />
        </div>
        <img
          src={imgSrc}
          className={styles.image}
          onClick={ignoreAction}
          onContextMenu={(e) => e.preventDefault()}
        />
      </div>
    </Dialog>
  );
}
